<template>
  <a-card :bordered="false">
    <div class="m-b-40">
      <p class="fz-18 s_font_b m-b-30 s_c000">数据概览</p>
      <a-row :gutter="20">
        <a-col :span="6" > 
          <a-card class="s_shou" :class="search.type==1?'on_select':''" @click="tabBnt(1)">
            <p class="fz-16 s_c000">短信发送数</p>
            <p class="s_font_b s_c000 fz-30 m-t-3 m-b-3">{{allData.all_send || 0}}</p>
            <div class="s_flex_ali">
              <p>昨</p>
              <span class=" m-l-5 m-r-5 balanceUp">{{allData.send || 0}}</span>
            </div>
          </a-card>
        </a-col>
        <a-col :span="6">
          <a-card class="s_shou" :class="search.type==2?'on_select':''" @click="tabBnt(2)">
            <p class="fz-16">短信充值数</p>
            <p class="s_font_b s_c000 fz-30 m-t-3 m-b-3">{{allData.all_order_num || 0}}</p>
            <div class="s_flex_ali">
              <p>昨</p>
              <span class=" m-l-5 m-r-5 balanceUp">{{allData.order_num || 0}}</span>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <div class="s_flex_bian_c">
      <p class="fz-18 s_font_b s_c000">{{search.type==1?'发送趋势':'充值趋势'}}</p>
      <div class="s_flex_ali">
        <a-range-picker v-model:value="time" valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" @change="onChange" />
        <a-button class="m-l-20" type="primary" @click='initData'>搜索</a-button>
      </div>
    </div>
    <a-spin :spinning="listLoading">
      <div class="m-t-30 width100">
        <echartLine :echartsData='echartsData' v-if='!listLoading'></echartLine>
      </div>
    </a-spin>
  </a-card>
</template>

<script>

  import { reactive, ref, toRefs, onMounted, inject } from "vue";
  import { getMessageStatistics } from "@/api/message";
  import { $iscode } from "@/utils/app";
  import { message } from "ant-design-vue";
  const defSearch = {
    type:1,
    start: "",
    end: ""
  };
  export default {
    setup(props) {
      let search = ref({ ...defSearch });
      const state = reactive({
        listLoading: false,
        echartsData: {
          dates: [],
          counts: [],
          name: '发送趋势'
        },
        time: [],
        allData: {},
      })
      const onChange = (e) => {
        search.value.start = e[0];
        search.value.end = e[1];
      };
      const tabBnt = (e) =>{
        if(search.value.type == e){
          return
        }
        search.value.type = e;
        state.echartsData.name = e==1?'发送趋势':'充值趋势';
        initData();
      }
      const initData = async () => {
        state.listLoading = true;
        try {
          let res = await getMessageStatistics(search.value).then((res) => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            state.allData = res.data;
            var dates = [];
            var counts = [];
            res.data.statistics.map(v=>{
              dates.push(v.date)
              counts.push(v.sent)
            })
            state.echartsData.dates = dates;
            state.echartsData.counts = counts;
          } else {
            message.error(res.message);
          }
        } catch (e) {
          state.listLoading = false;
        }
      };
      onMounted(() => {
        initData()
      })
      return {
        ...toRefs(state),
        onChange,
        search,
        tabBnt,
        initData
      }
    }

  }
</script>
<style>
  .balanceUp {
    color: #DF412C;
  }

  .balanceDown {
    color: #4BB339;
  }
  .on_select{
    box-shadow: 0px 0px 22px 0px #dbe7f3;
  }
</style>